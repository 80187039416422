<template>
	<div class="basket">
		<transition name="fade" mode="out-in">
			<v-container v-if="isSubmitted" class="text-center">

				<h1 class="mt-8">Your order is being processed</h1>
				<p class="mt-4">Please wait...</p>

				<v-progress-circular class="mt-8"
									 indeterminate
									 color="grey"/>

			</v-container>
			<v-container v-else-if="isEmpty" class="text-center">
				<h3>{{page.texts.BASKET_CART_EMPTY.NAME}}<!--YOUR CART IS EMPTY--></h3>

				<btn-link :to="{ name: 'shop' }" class="mx-auto mt-8 mb-15" :label="shopNowText"/>
			</v-container>
			<div v-else class="basket__wrapper mx-auto padding-top-main">
				<basket-items :items="items"/>
				<basket-form :items="items" @ok="onSubmitted"/>
			</div>
		</transition>
	</div>
</template>

<script>
	import BasketForm from "@/views/Basket/basket-form";
	import BasketItems from "@/views/Basket/basket-items";
	import BtnLink from "@/components/btn-link";

	export default {
		name: "basket",
		components: {
			BasketItems,
			BasketForm,
			BtnLink,
		},
		data() {
			return {
				//basket: null
				isSubmitted: false
			}
		},
		computed: {
			items() {
				return this.$store.state.session.basket;
			},
			isEmpty() {
				return !this.items?.length;
			},
			shopNowText() {
				return this.page.texts?.HOME_SHOP_NOW?.NAME;
			},
			page() {
				return this.$store.state.page.BasketController;
			},
		},
		methods: {
			onSubmitted() {
				/*// todo загрузка OrderController долгая, она надолго скрывает корзину - не нужно ее скрывать
				setTimeout(()=>{
					this.$store.state.isLoading = false;
				},150);*/
				this.isSubmitted = true;
			},
			/*reinitBasket() {
				// специально копируем, чтобы не терять это при очистки сессии, когда происходит отправка заказа
				this.basket = this.$store.state.session?.basket ? [...this.$store.state.session?.basket] : null;
			}*/
		},
		created() {
			//this.reinitBasket();
		},
		mounted() {
			this.isSubmitted = false;
		}
	}
</script>

<style lang="scss">
	.basket {
		margin-top: 128px;

		&__wrapper {
			position: relative;
			@include up($sm) {
				max-width: 952px;
			}
			@include up($lg) {
				max-width: 1048px;
			}

		}


	}
</style>