<template>
	<div class="basket-items">
		<v-container>
			<!--      todo margin-bottom main, rename your cart, button, rename components (Carts, Cart)-->
			<h1 class="text-center margin-bottom-main mb-16 h3">{{this.page.texts.BASKET_YOUR_CART.NAME}}<!--YOUR CART--></h1>
			<button v-if="items" class="basket-items__clear d-none d-sm-block" @click="onClear">
				<span class="size1">{{page.texts.BASKET_CLEAR.NAME}}<!--Clear--></span>
			</button>

			<!-- todo PLEASE rename this to basket-item -->
			<basket-item v-for="(item, index) of items" :key="'item' + index" :item="item"
				  @change="onAmountChangeDebounced"/>

			<h4 class="basket-items__subtotal">{{page.texts.BASKET_SUBTOTAL.NAME}}<!--SUBTOTAL--> :
				<span class="ml-1">{{ total | nf(2) }} €</span></h4>
		</v-container>

		<v-dialog
				v-model="isClearing"
				:persistent="isClearingConfirmed"
				:content-class="'popup'"
				max-width="400px">
			<v-card class="glass">

				<v-card-title class="pt-8 justify-center">
					<div class="h5">Do you want to empty the cart?</div>
				</v-card-title>
				<v-card-text class="text-center">

				</v-card-text>
				<v-card-actions class="d-flex justify-space-between">
					<!--<btn compact @click="isClearing=false" :disabled="isClearingConfirmed">No</btn>
					<btn compact :busy="isClearingConfirmed" @click="onClearConfirmed">Yes</btn>-->
					<BtnText compact @click="isClearing=false" :disabled="isClearingConfirmed" label="No"/>
					<BtnText compact :loading="isClearingConfirmed" @click="onClearConfirmed" label="Yes"/>
				</v-card-actions>

			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	//import Cart from "./cart"
	import Divider from "@/components/divider";
	import {debounce} from "lodash";
	import Config from "@/config";
	import BasketItem from "@/views/Basket/basket-item";
	import BtnPrimary from "@/components/btn-primary";
	import BtnText from "@/components/btn-text";

	export default {
		name: "basket-items",
		components: {
			BtnText,
			BtnPrimary,
			BasketItem,
			Divider,
		},
		props: {
			items: Array
		},
		data() {
			return {
				isClearing: false,
				isClearingConfirmed: false,
			}
		},

		computed: {
			total() {
				const productsTotal = this.items.reduce((sum, item) => sum + item.PRICE * Number(item.AMOUNT), 0);
				//const deliveryCost = this.deliveryCost;
				return productsTotal;
			},
			page() {
				return this.$store.state.page.BasketController;
			},
		},
		methods: {
			onClear() {
				this.isClearing = true;
			},
			onAmountChangeDebounced: debounce(function () {

				const items = this.items.map(item=>({
					id: item.ID,	// это BasketItem.ID
					amount: item.AMOUNT
				}));

				this.$store.dispatch('post', {
					action: 'BasketUpdateController',
					params: {
						items
					}
				}).then((result) => {
					this.$store.commit('setSession', result);

					this.isClearing = false;
					this.isClearingConfirmed = false;

				}).catch((error) => {
					// this.error = error;
					this.error = "Произошла ошибка. Пожалуйста, попробуйте снова."
				}).finally(() => {
					this.isClearingConfirmed = false;
				});

			}, Config.DEBOUNCE_INPUT_TIMEOUT),
			onClearConfirmed() {
				this.isClearingConfirmed = true;
				this.$store.dispatch('post', {
					action: 'BasketClearController',
				}).then((result) => {
					this.$store.commit('setSession', result);

					this.isClearing = false;
					this.isClearingConfirmed = false;

				}).catch((error) => {
					// this.error = error;
					this.error = "Произошла ошибка. Пожалуйста, попробуйте снова."
				}).finally(() => {
					this.isClearingConfirmed = false;
				});
			},
		}

	}
</script>

<style lang="scss">
	.basket-items {
		.container {
			position: relative !important;
		}

		&__subtotal {
			text-align: center;

			@include up($md) {
				text-align: right;
				margin-right: 52px;
			}
		}

		&__clear {
			position: absolute;
			top: 32px;
			right: $container-padding-x;

			span {
				@include transition();
				color: $black-100;

				&:hover {
					color: $black-200;
				}
			}
		}


		h3, h4, .my-text-2 {
			color: $primary-500;
		}
	}
</style>