<template>
    <section v-if="items" class="basket-form" id="checkout">
        <v-container v-if="isCheckingOut" key="2">
            <v-form @submit.prevent="submit">
                <div class="text-center mb-10">
                    <h4 class="color-golden" @click.ctrl="fillForm">{{ page.texts.BAKSET_DELIVERY_DETAIL.NAME }}
                        <!--Delivery details--></h4>

                    <p class="mb-10">{{ page.texts.BASKET_WE_EUROPE.NAME }}<!--We deliver throughout Europe--></p>

                    <v-row>
                        <v-col cols="12">

                            <h4>{{ page.texts.BASKET_PLEASE_CHOOSE.NAME }}<!--Please choose destination--></h4>

                            <div class="d-flex justify-center">
                                <v-radio-group v-model="form.deliveryType" :row="isSM">
                                    <v-radio :label="page.texts.BASKET_PARIS.NAME" :value="1"/>
                                    <v-radio :label="page.texts.BASKET_OTHER.NAME" :value="2"/>
                                    <v-radio :label="page.texts.BASKET_EUROPE.NAME" :value="3"/>
                                </v-radio-group>
                            </div>
                        </v-col>
                    </v-row>
                    <template v-if="form.deliveryType">
                        <v-row v-if="form.deliveryType!==1" class="justify-center">
                            <v-col cols="12" sm="2">
                                <v-text-field v-model="form.PERSONAL_ZIP" :label="page.texts.BASKET_POST.NAME"
                                              maxlength="16"
                                              required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5">
                                <v-text-field v-if="form.deliveryType===2" v-model="COUNTRY_FRANCE"
                                              :label="page.texts.BASKET_COUNTRY.NAME"
                                              maxlength="32"
                                              readonly/>
                                <v-text-field v-else v-model="form.PERSONAL_COUNTRY"
                                              :label="page.texts.BASKET_COUNTRY.NAME"
                                              :readonly="form.deliveryType===2"
                                              maxlength="32"
                                              required/>
                            </v-col>
                            <v-col cols="12" sm="5">
                                <v-text-field v-model="form.PERSONAL_CITY"
                                              :label="page.texts.BASKET_CITY.NAME"
                                              maxlength="32"
                                              required/>
                            </v-col>
                        </v-row>
                        <v-row v-if="form.deliveryType" class="justify-center">
                            <v-col cols="12" sm="4">
                                <v-text-field v-model="form.PERSONAL_STATE"
                                              :label="page.texts.BASKET_DISTRICT.NAME"
                                              maxlength="32"
                                              required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field v-model="form.PERSONAL_STREET" :label="page.texts.BASKET_STREET.NAME"
                                              maxlength="80"
                                              required></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="2">
                                <v-text-field v-model="form.PERSONAL_MAILBOX"
                                              :label="page.texts.BASKET_BILDING.NAME"
                                              maxlength="32"
                                              required/>
                            </v-col>
                            <v-col cols="6" sm="2">
                                <v-text-field v-model="form.PERSONAL_NOTES"
                                              :label="page.texts.BASKET_ROOM.NAME"
                                              maxlength="32"
                                              required/>
                            </v-col>
                        </v-row>
                    </template>
                </div>
                <template v-if="form.deliveryType">
                    <div class="text-center mb-10">
                        <h4 class="color-golden mb-10">{{ page.texts.BASKET_CONTACT_INFO.NAME }}
                            <!--Contact information--></h4>

                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field v-model="form.NAME"
                                              :label="page.texts.BASKET_NAME.NAME"
                                              maxlength="80"
                                              required/>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field v-model="form.EMAIL"
                                              :label="page.texts.BASKET_EMAIL.NAME"
                                              maxlength="128"
                                              required/>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field v-model="form.PERSONAL_PHONE"
                                              @keydown="filterPhoneKey"
                                              @keyup="fixPhone"
                                              :label="page.texts.BASKET_PHONE.NAME"
                                              :maxlength="phoneMaxlength"
                                              required/>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="text-center mb-10">
                        <h4 class="mb-10 color-golden">{{ page.texts.BASKET_COMMENTS.NAME }}
                            <!--Valuable Comments--></h4>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea v-model="form.COMMENTS"
                                            rows="1" auto-grow
                                            :placeholder="page.texts.BAKSET_PLEASE_PROVIDE.NAME"
                                />
                            </v-col>
                        </v-row>
                    </div>
                    <div>
                        <!--          <h4 class="text-center my-primary mb-16">SELECT PAYMENT</h4>
                                  <div class="payment__wrapper mb-8 d-flex justify-center">
                                    <BtnPrimary label="CARD ONLINE" class="mr-5"/>
                                    <BtnPrimary disabled="true" label="CASH"/>
                                  </div>-->
                        <div class="d-flex justify-center align-center mb-8">
                            <v-checkbox v-model="hasAccepted">
                                <template slot="label">
                                    {{ page.texts.BASKET_I_HAVE.NAME }}<!--I have read and accept the-->
                                    <router-link :to="{name:'terms'}" target="_blank" @click.native.stop=""><!--General terms and
										conditions of sale-->
                                        <span>
                                        {{ page.texts.BASKET_GENERAL.NAME }}
                                            </span>
                                    </router-link>
                                </template>
                            </v-checkbox>
                        </div>
                        <div class="text-center">{{ page.texts.BASKET_DELIVERY.NAME }}<!--Delivery cost--> :
                            <span class="ml-1">{{ deliveryCost | nf(2) }} €</span></div>
                        <h3 class="text-center mt-4 mb-8">{{ page.texts.BASKET_TOTAL.NAME }}<!--TOTAL--> : <span
                            class="ml-1">{{ total | nf(2) }} €</span></h3>
                        <btn-primary type="submit"
                                    class="d-block large mx-auto"
                                    :disabled="!hasAccepted"
                                    :loading="loading"
                                    :label="page.texts.BASKET_PROCEED.NAME"/>
                        <p v-if="!hasAccepted" class="text-center mt-8">{{ page.texts.BASKET_PLEASE_TICK.NAME }}
                            <!--Please tick the above checkbox about our General terms and conditions of sale--></p>
                        <!--          <button class="d-block d-sm-none payment__delete mx-auto d-block mt-8">
                                    <span class="size1 ">Clear cart</span>
                                  </button>-->
                    </div>
                </template>
            </v-form>
        </v-container>
        <v-container v-else key="2" class="text-center">
            <btn-primary large @click="onCheckout">{{page.texts.BASKET_CHECKOUT.NAME}}<!--Check out--></btn-primary>
        </v-container>

        <v-dialog v-model="isErrorDialog" width="500">
            <v-card>
                <v-card-title class="justify-center">Check the form</v-card-title>

                <v-card-text class="justify-center text-center size-sm-md">
                    {{ error }}
                </v-card-text>

                <v-card-actions class="justify-center">
                    <v-btn
                        text
                        @click="error = null; isErrorDialog = null;">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="isSuccessDialog" width="500">
            <v-card>
                <v-card-title class="justify-center">Success!</v-card-title>

                <!--        <v-card-text class="justify-center text-center size-sm-md">
                        </v-card-text>-->

                <v-card-actions class="justify-center">
                    <v-btn text @click="isSuccessDialog = null">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </section>
</template>

<script>
import Divider from "../../components/divider";
import BtnPrimary from '../../components/btn-primary';
import {scrollTo} from '@/utils/utils';
import vp from '@/mixins/vp';

export default {
    name: "basket-form",
    components: {
        BtnPrimary,
        Divider
    },
    mixins: [vp],
    props: ["items"],
    data() {
        return {
            COUNTRY_FRANCE: "France",
            form: {
                deliveryType: 0,
                NAME: '',
                EMAIL: '',
                PERSONAL_PHONE: '',
                PERSONAL_ZIP: '',
                PERSONAL_COUNTRY: '',
                PERSONAL_CITY: '',
                PERSONAL_STATE: '',
                PERSONAL_STREET: '',
                PERSONAL_MAILBOX: '',
                PERSONAL_NOTES: '',
                COMMENTS: ''
            },
            hasAccepted: false,
            loading: false,
            isErrorDialog: false,
            isSuccessDialog: false,
            error: null,
            phoneMaxlength: 32,
            isCheckingOut: false,
        }
    },
    computed: {
        total() {
            const productsTotal = this.items.reduce((sum, item) => sum + item.PRICE * Number(item.AMOUNT), 0);
            const deliveryCost = this.deliveryCost;
            return productsTotal + deliveryCost;
        },
        deliveryCost() {
            // todo move to CMS
            if (this.form.deliveryType === 1) return 10;
            else if (this.form.deliveryType === 2) return 10;
            return 20;
        },
        page() {
            return this.$store.state.page.BasketController;
        }
    },
    methods: {
        onCheckout() {
            this.isCheckingOut = true;
            setTimeout(() => {
                scrollTo('checkout', -100);
            }, 300);
        },
        submit() {

            const vals = [
                {
                    re: /^.{3,}$/,
                    cond: this.form.deliveryType === 2 || this.form.deliveryType === 3,
                    value: this.form.PERSONAL_ZIP,
                    msg: "Please specify postal code"
                },
                {
                    re: /^.{2,}$/,
                    cond: this.form.deliveryType === 3,
                    value: this.form.PERSONAL_COUNTRY,
                    msg: "Please specify country"
                },
                {
                    re: /^.{2,}$/,
                    cond: this.form.deliveryType === 2 || this.form.deliveryType === 3,
                    value: this.form.PERSONAL_CITY,
                    msg: "Please specify city"
                },
                {
                    re: /^.{2,}$/,
                    value: this.form.PERSONAL_STREET,
                    msg: "Please specify delivery address"
                },
                {
                    re: /^.+$/,
                    value: this.form.PERSONAL_MAILBOX,
                    msg: "Please specify building number"
                },
                {
                    re: /^.+$/,
                    value: this.form.PERSONAL_NOTES,
                    msg: "Please specify room number"
                },
                {
                    re: /^.{2,}$/,
                    value: this.form.NAME,
                    msg: "Please specify your name"
                },
                {
                    re: /^.+?@.+?\..+$/,
                    value: this.form.EMAIL,
                    msg: "Please specify your email"
                },
                {
                    re: /^\d{10,24}$/,
                    value: this.form.PERSONAL_PHONE.replace(/\D+/g, ""),
                    msg: "Please specify your phone number"
                },
                {
                    re: /^\d{10,24}$/,
                    value: this.form.PERSONAL_PHONE.replace(/\D+/g, ""),
                    msg: "Please specify your phone number"
                }
            ];

            this.isErrorDialog = false;
            vals.forEach(el => {
                if (this.isErrorDialog) return;
                if (typeof el.cond !== "undefined" && !el.cond) return;
                if (!el.re.test(el.value)) {
                    this.isErrorDialog = true;
                    return (this.error = el.msg);
                }
            });
            if (this.isErrorDialog) return;

            this.loading = true;
            this.error = null;
            this.$store.dispatch('post', {
                action: 'OrderController',
                params: this.form
            }).then((res) => {
                ///this.isSuccessRedirection = true;
                this.$emit("ok", res);
                this.$router.push({name: 'order', params: {id: res?.page?.OrderController?.order?.ID}});
            }).catch((error) => {
                // this.error = error;
                this.error = "Something went wrong. Please try again.";
                this.isErrorDialog = true;
                this.loading = false;
            }).finally(() => {
                //this.loading = false;
            });
        },
        fillForm() {
            this.form.deliveryType = 2;
            this.form.NAME = "PlayNext test";
            this.form.EMAIL = "test@playnext.ru";
            this.form.PERSONAL_PHONE = "01 99 99 99 99";
            this.form.PERSONAL_ZIP = "9999999";
            this.form.PERSONAL_CITY = "Marseille";
            this.form.PERSONAL_STATE = "Test district";
            this.form.PERSONAL_STREET = "Test street";
            this.form.PERSONAL_MAILBOX = "Building No.";
            this.form.PERSONAL_NOTES = "Room No.";
            this.form.COMMENTS = "Some nice test comment here";
            this.hasAccepted = true;
        },
        filterPhoneKey(e) {
            //console.log(e);
            const a = e.key;

            // ignore arrows, del, backspace
            if (
                e.keyCode === 37 ||
                e.keyCode === 39 ||
                e.keyCode === 8 ||
                e.keyCode === 46 ||
                e.keyCode === 35 ||
                e.keyCode === 36 ||
                e.keyCode === 9 ||
                (e.key === "x" && e.ctrlKey) ||
                (e.key === "c" && e.ctrlKey) ||
                (e.key === "v" && e.ctrlKey)
            )
                return true;

            if (!this.validatePhoneKey(a)) e.preventDefault();
        },
        validatePhoneKey(a) {
            if (!a.match(/[+0-9\(\) -]/)) return false;

            let l = this.form.PERSONAL_PHONE?.length;
            //console.log("a: "+a+", l: "+l);
            if (l === 0) {
                if (!a.match(/[+0-9]/)) return false;
                if (a === "+") this.form.PERSONAL_PHONE = "";
                else if (a !== "8") this.form.PERSONAL_PHONE = "+";
                else if (a === "9") this.form.PERSONAL_PHONE = "+7 (";
            } else if (l === 1) {
                if (this.form.PERSONAL_PHONE === "+" && a === "8") this.form.PERSONAL_PHONE = "";
            }
            return true;
        },
        fixPhone(e) {
            if (
                e &&
                (e.keyCode === 37 ||
                    e.keyCode === 39 ||
                    e.keyCode === 8 ||
                    e.keyCode === 46 ||
                    e.keyCode === 35 ||
                    e.keyCode === 36)
            )
                return true;

            let s = this.form.PERSONAL_PHONE;
            s = s.replace(/[^0-9]+/g, "");
            //console.log("s=" + s);
            //v = v.substr(1);

            this.phoneMaxlength = 32;
            if (!s.length) return "";
            const f = [];
            for (let i = 0; i < s.length; i++) {
                let l = s[i];
                //if ( i === 0 && l === "+" )
                //console.log(i + "=" + l);

                if (s[0] === "0") {
                    // +3 ...
                    //this.phoneMaxlength = 17;
                    if (i === 0) {
                        //f.push("+");
                    } else if (i === 2) {
                        f.push(" ");
                    } else if (i === 4) {
                        f.push(" ");
                    } else if (i === 6) {
                        f.push(" ");
                    } else if (i === 8) {
                        f.push(" ");
                    } else if (i === 10) {
                        f.push(" ");
                    }
                    f.push(l);
                } else {
                    // [0-6] ...
                    //this.phoneMaxlength = 24;
                    if (i === 0) {
                        f.push("+");
                    }/* else if (i === 3) {
							f.push(" ");
						}*/
                    f.push(l);
                }
            }
            const p = f.join("");
            if (p === this.form.PERSONAL_PHONE) return;
            this.form.PERSONAL_PHONE = p;
        },
    }
}
</script>

<style lang="scss">
.basket-form {
    padding-bottom: 128px;

    .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
        display: block;
    }
}
</style>